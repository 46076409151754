import React from "react"
// import { Link } from "gatsby" // we use wrapper one from plugin = gatsby-plugin-intl
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { graphql } from "gatsby"

// s4n custom code
// import FragmentPageDetails from '../model/fragments/nodePage/page-details'
import FragmentProductRelated from '../model/fragments/commerceProductDefault/product-related'
import { injectIntl } from "gatsby-plugin-intl"
import { useParseInlineImages } from '../hooks/drupal/parseInlineImages'
import loadable from '@loadable/component'
// import DrupalModuleParagraphsContainer from './../components/drupal/module/paragraphs/container'
// import Widgets from "./../components/widgets"
// import PromotedFrontPage from './../components/s4n/Promoted/FrontPage/index';

// import Calendar from './../components/s4n/Calendar'
const Calendar = loadable(() => import('./../components/s4n/Calendar'))


const IndexPage = (props) => {

    const { data, intl } = props;
    // const t = intl.formatMessage;

    // const node = data.allNodePage.edges[0].node;
    // const { node } = data.allNodePage.edges.filter(({ node }) => intl.locale === node.langcode)[0];
    let nodeInLanguage = data.allNodePage.edges.filter(({ node }) => intl.locale === node.langcode); // just for netlify!!!
// console.log(`nodeInLanguage: `, nodeInLanguage);

    if (!!nodeInLanguage.length) {
      nodeInLanguage = nodeInLanguage[0].node;
    } else {
      nodeInLanguage = null;
    }



    const ProductRelated = loadable(() => import(`./../components/s4n/Shop/Product/related`));
    const Subpages = loadable(() => import(`./../components/s4n/Menu/subpages`));
    let oReplacement = {};
    if (nodeInLanguage) {
      oReplacement = {
        [`[s4n_related_products]`]: <ProductRelated disableHeader={true} products={nodeInLanguage.relationships && nodeInLanguage.relationships.field_related_products ? nodeInLanguage.relationships.field_related_products : []} />,
        [`[s4n_subpages]`]: <Subpages nid={nodeInLanguage.drupal_internal__nid} exclude={[866]} displaySectionShortDescriptionForParentNodeId={[1054]} />, // exclude globally about-us/news :)
        [`[s4n_calendar]`]: <Calendar />,
        // [`[s4n_calendar]`]: <></>,
      }
    }



    let nodeBodyField = null;
    // let nodeParagraphField = null;

    // if (nodeInLanguage) {
      // prepare/extract data
      // let nodeBodyField = node.body ? node.body.value : null;
      nodeBodyField = useParseInlineImages(nodeInLanguage, intl, true, oReplacement) // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
      // nodeParagraphField = nodeInLanguage.relationships ? nodeInLanguage.relationships.field_paragraph : null
      // let nodeParagraphField = useParseBodyInlineImages(nodeInLanguage.relationships.field_paragraph)
    // }

    return (
      <Layout node={nodeInLanguage}>

        <SEO 
          title="Home" _keywords={``} 
          nodeId={nodeInLanguage?.drupal_internal__nid} 
          field_metatag={nodeInLanguage?.field_metatag}
        />

        {/* <h1>Hi people!</h1> */}
        {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
          <Image />
        </div> */}
        {/* <Link to="/page-2/">Go to page 2</Link> */}
        {/* { t({ id: "title" }) } */}
        {/* <p>{ nodeInLanguage.title }</p> */}
        {/* <div dangerouslySetInnerHTML={{ __html: nodeInLanguage.body ? nodeInLanguage.body.value : '' }}></div> */}

        { nodeBodyField }

        {/* <PromotedFrontPage /> */}

        {/* @see: https://github.com/gatsbyjs/gatsby/issues/8787 */}
        {/* { '/pl/' === props.location.pathname && <Widgets />} */}
    
        {/* <DrupalModuleParagraphsContainer>
          { nodeParagraphField }
        </DrupalModuleParagraphsContainer> */}
    
        {/* {node.edges.map(({ node }) => {
          return (
            <>
              <h3><Link to={ node.path.alias }>{ node.title }</Link></h3>
              <small><em>{ node.created }</em></small>
              <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value.split(' ').splice(0, 50).join(' ') + '...' : '' }}></div>
              <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value : '' }}></div>
            </>
          )
        })} */}
    
      </Layout>
    )
}

export default injectIntl(IndexPage)

export const query = graphql`
  query {
    allNodePage(
        filter: {
          drupal_internal__nid: {
            eq: 7
          }
          # langcode: {
          #   eq: "pl"
          # }
        }
      ) {
        edges {
          node {

            # We canont use this fragmen since it has an option "on node__page" whike this is other type
            # ...FragmentPageDetails

            id
            drupal_id
            drupal_internal__nid
            internal {
                type
            }

            title
            langcode
            path {
              alias
              langcode
            }
            created
            body {
              summary
              value
            }



            # field_fake      
            related_collection_mediaImageNodes {
                field_media_image {
                    alt
                    title
                    width
                    height
                }
                relationships {
                    field_media_image {
                        drupal_id
                    }
                }
            }
            related_collection_fileFileNodes {
                # __typename
                drupal_id
                drupal_internal__fid
                langcode
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                        # fluid(maxWidth: 1480) {
                            # ...GatsbyImageSharpFluid
                            # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }



            field_metatag {
                # basic
                    title
                    description
                    abstract
                    keywords
                # advanced
                    # canonical_url
                    # cache_control
            }

            relationships {
                __typename

                # @todo: Extract as fragment
                field_related_products {
                    ... on commerce_product__default {
                        ...FragmentProductRelated
                    }
                }
            }

          }
        }
    }
  }
`
